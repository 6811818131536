import { AxiosError } from 'axios';
import { useHotjar } from './useHotjar';
import { AccountLanguage } from './useLanguageService';
import * as Sentry from '@sentry/vue';
import { useUserStore } from '@/stores/userStore';

export interface StrapiError {
  data: null;
  error: {
    status: number;
    name: string;
    message: string;
    details?: {
      errors?: {
        message: string;
        name: string;
        path: string[];
      }[];
      message_key?: string;
      language?: AccountLanguage;
      user?: string;
    };
  };
}

const { emitErrorEvent } = useHotjar();

export function useHandleError() {
  function handleError(error: unknown) {
    const errorMessages: string[] = [];
    if (!isAxiosError(error)) {
      throwError(error);
      return;
    }

    if (!error.response?.data.error) {
      throwError(error);
      return;
    }

    if (
      error.response?.data.error.name !== 'ApplicationError' &&
      error.response?.data.error.name !== 'ValidationError' &&
      error.response?.data.error.name !== 'ForbiddenError'
    ) {
      throwError(error);
      return;
    }

    if (error.response?.data.error.details?.message_key?.toString()) {
      const errorDetails = error.response?.data.error.details;
      errorMessages.push(JSON.stringify(errorDetails));
      return errorMessages;
    }

    if (error.response?.data.error.details?.errors) {
      const responseErrors = error.response?.data.error.details?.errors;
      responseErrors.forEach(element => {
        errorMessages.push(element.message);
      });
      return errorMessages;
    }

    if (error.response?.data.error.message) {
      errorMessages.push(error.response?.data.error.message);
      return errorMessages;
    }
    throwError(error);
    return;
  }

  function throwError(error: unknown) {
    emitErrorEvent();
    const userStore = useUserStore();
    const scope = new Sentry.Scope();
    scope.setContext('UserStore', userStore.loggedInInfo?.user || {});
    Sentry.captureException(error, scope);
  }

  function isAxiosError(error: unknown): error is AxiosError<StrapiError> {
    return (error as AxiosError<StrapiError>)?.isAxiosError === true;
  }

  function checkForKnownError(error: unknown, expectedErrorMessage: string) {
    if (!isAxiosError(error)) {
      return false;
    }
    return error.response?.data.error.message === expectedErrorMessage;
  }

  function checkForKnowErrorMessageKey(error: unknown, expectedErrorMessageKey: string) {
    if (!isAxiosError(error) || !error.response?.data.error.details?.message_key?.toString()) {
      return false;
    }
    return error.response?.data.error.details?.message_key === expectedErrorMessageKey;
  }

  return {
    handleError,
    throwError,
    checkForKnownError,
    checkForKnowErrorMessageKey,
  };
}
