import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { useUserService } from '../services/userDataService';
import { useUserStore } from '../stores/userStore';
import { envs } from '../env';
import useSubscriptionService from '../services/subscriptionService';
import { routes as empireRoutes } from '../views/empire/routes';
import { routes as liberidisognareRoutes } from '../views/liberidisognare/routes';
import { routes as dbmRoutes } from '../views/dbm/routes';
import { routes as rocknrollRoutes } from '../views/rocknroll/routes';
import { routes as iconcommunityRoutes } from '../views/iconcommunity/routes';
import { routes as latinasempoderadasRoutes } from '../views/latinasempoderadas/routes';
import { routes as designbundles } from '../views/designbundles/routes';
import { routes as ketoneaffiliate } from '../views/ketoneaffiliate/routes';
import { routes as workflow } from '../views/workflow/routes';
import { routes as bombshell } from '../views/bombshell/routes';
import { routes as makelifewowteamRoutes } from '../views/makelifewowteam/routes';
import { routes as legendaryEmpireRoutes } from '../views/legendaryempire/routes';
import { routes as goldenglobalteamRoutes } from '../views/goldenglobalteam/routes';
import { TenantDTO, useTenantService } from '@/services/tenantService';
import { useRouterHelpers } from './useRouterHelpers';
import { routes as sharedRoutes } from '../views/shared/routes';

export const availableRoutesArray = [
  'MainLayout',
  'LandingPage',
  'DashboardHome',
  'ProfilePage',
  'ViewAllLeads',
  'LeadsPage',
  'ManageSubscription',
  'SagaMessagingPage',
  'ImportContacts',
  'HostLogin',
  'SupportCenter',
  'ForgotPassword',
  'RegisterPage',
  'HelpCenter',
  'ChatBotRedirect',
  'BettyBotTrainingPage',
  'PageNotFound',
  'SagaLinkPage',
  'FastMessagingPage',
  'MassMessagingPage',
  'LinkMateLeadPage',
  'FollowUpPage',
  'LinkMateLayout',
  'LinkMateOnboarding',
  'LinkMatePage',
  'OnBoardingPage',
  'DMOPage',
  'ProductInformationFlow',
  'ProductInfoPageQuestionaire',
  'BusinessInfoPageQuestionaire',
  'BusinessInformationFlow',
  'KetoinfoSolutionPage',
  'OpportunitySolutionPage',
  'UserflowHelp',
  'ProspectingToolsSettingsPage',
  'UnicornPage',
  'BroadcastPage',
  'EmailListsPage',
  'LeadMagnetPage',
  'InfoPageLinkPage',
  'LeadMagnetLinkPage',
  'WelcomePage',
  'OutsetaSignUpPage',
  'ProductInformationFlowCollagen',
  'ProductInformationFlowNutrition',
] as const;

export type AvailableRoutes = (typeof availableRoutesArray)[number];

export type RouteWithName = RouteRecordRaw & { name?: AvailableRoutes; children?: RouteWithName[] };
declare module 'vue-router' {
  interface RouteMeta {
    // must be declared by every route
    requiresAuth: boolean;
    isFullPage?: boolean;
    hasAdminPage?: boolean;
  }
}

const VITE_LINKMATE_PREFIX = envs.VITE_LINKMATE_PREFIX;

const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...sharedRoutes,
    ...empireRoutes,
    ...liberidisognareRoutes,
    ...dbmRoutes,
    ...latinasempoderadasRoutes,
    ...rocknrollRoutes,
    ...iconcommunityRoutes,
    ...designbundles,
    ...ketoneaffiliate,
    ...workflow,
    ...bombshell,
    ...makelifewowteamRoutes,
    ...legendaryEmpireRoutes,
    ...goldenglobalteamRoutes,
  ],
  scrollBehavior(to) {
    if (to.hash) {
      const section = document.querySelector(to.hash);
      section?.scrollIntoView({ behavior: 'smooth' });
    } else window.document.getElementById('main-route')?.scrollTo({ top: 0 });
  },
});

router.beforeEach(async (to, from, next) => {
  const tenantService = useTenantService();
  const { getRouteName, getNewTenantPath } = useRouterHelpers();

  if (
    window.location.origin === `${VITE_LINKMATE_PREFIX}` &&
    !to.path.includes('/link') &&
    (to.name != getRouteName('PageNotFound') || from.name === undefined)
  ) {
    const redirectPath = '/link' + to.path;
    next(redirectPath);
    return;
  }
  const userStore = useUserStore();

  if (useUserService().isUpdateNeeded()) {
    await userStore.updateLoggedInUserInfo();
  }

  const tenant = await computeTenant(to, userStore.loggedInInfo?.user?.tenant);
  if (tenant && !tenantService.isCorrectTenant(tenant.tenant)) {
    tenantService.setTenant(tenant.tenant);
    const newTenantPath = getNewTenantPath(tenant.tenant, to.fullPath);
    next(newTenantPath);
    return;
  }

  if (!to.meta.requiresAuth || to.name === getRouteName('HelpCenter')) {
    next();
    return;
  }

  if (!userStore.loggedInInfo?.jwt) {
    next({ name: getRouteName('HostLogin') });
    return;
  }

  if (!userStore.loggedInInfo?.user?.outsetaId) {
    if (userStore.loggedInInfo?.user) {
      let outsetaId;
      const plans = await useSubscriptionService().getPlansByTenant();
      if (plans && plans.freeTrialDays > 0) {
        outsetaId = await useSubscriptionService().createOutsetaAccount(plans.planFamilyName);
      } else {
        next({ name: getRouteName('OutsetaSignUpPage') });
        return;
      }
      if (!outsetaId) {
        userStore.logout();
        return;
      }
      await useSubscriptionService().handleLoggedInUserSubscription(outsetaId);
    }
    next();
    return;
  }

  if (!userStore.isUserSubscribed && to.name != getRouteName('ManageSubscription')) {
    next({ name: getRouteName('ManageSubscription') });
    return;
  }
  if (userStore.adminStatus.isAdminModeActive && !to.meta.hasAdminPage) {
    next({ name: getRouteName('OnBoardingPage') });
    return;
  }

  if (to.name === getRouteName('WelcomePage') && userStore.loggedInInfo.user.hasBeenWelcomed) {
    next({ name: getRouteName('DashboardHome') });
    return;
  }
  next();
});

const computeTenant = async (toRoute: RouteLocationNormalized, loggedInTenant?: TenantDTO) => {
  if (!toRoute.path.includes('/link') || !toRoute.params.linkName || typeof toRoute.params.linkName !== 'string') {
    return loggedInTenant;
  }
  const { getUserFromLinkMateNickName } = useUserService();
  const user = await getUserFromLinkMateNickName(toRoute.params.linkName);
  return user?.tenant;
};

export default router;
