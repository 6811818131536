import { LeadInterest, LeadMembership, SourceOptions } from '@/services/leadDataService';

export const sourcesToShow: SourceOptions[] = [
  'Manually Created',
  'CONTACT_ME_FORM',
  'INFORMATION_FLOW_FORM',
  'INFORMATION_FLOW',
  'LEAD_MAGNET',
  'CSV',
];
export const membershipsToShow: LeadMembership[] = ['Lead', 'Customer', 'Promoter', 'Lost'];

export const interestsToShow: LeadInterest[] = ['business', 'product'];
