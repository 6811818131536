export default `<svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.5138 0C5.90038 0 0 5.57118 0 13.0955C0 17.0314 1.6151 20.4323 4.24447 22.7819C4.46474 22.9802 4.59792 23.2545 4.6088 23.5506L4.68225 25.9518C4.70671 26.7178 5.49794 27.2149 6.1995 26.908L8.8805 25.7264C9.10731 25.626 9.36176 25.6069 9.60105 25.6721C10.8328 26.0116 12.1461 26.1909 13.5138 26.1909C21.1271 26.1909 27.0275 20.6197 27.0275 13.0954C27.0275 5.57118 21.1271 0 13.5138 0Z" fill="url(#paint0_linear_729_8410)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.4001 16.9252L9.3699 10.6342C10.0007 9.63459 11.3548 9.38465 12.301 10.0937L15.4578 12.4596C15.5989 12.565 15.7703 12.6217 15.9464 12.6212C16.1224 12.6207 16.2936 12.563 16.434 12.4569L20.6975 9.22438C21.2658 8.79255 22.0109 9.47439 21.6274 10.0774L17.6603 16.3657C17.0295 17.3653 15.6754 17.6152 14.7292 16.9062L11.5724 14.5403C11.4314 14.4349 11.2599 14.3782 11.0838 14.3787C10.9078 14.3791 10.7366 14.4368 10.5962 14.543L6.32999 17.7782C5.76173 18.2101 5.01665 17.5282 5.4001 16.9252Z" fill="white"/>
<defs>
<linearGradient id="paint0_linear_729_8410" x1="5405.5" y1="0" x2="5405.5" y2="10875" gradientUnits="userSpaceOnUse">
<stop stop-color="#00B2FF"/>
<stop offset="1" stop-color="#006AFF"/>
</linearGradient>
</defs>
</svg>

`;
