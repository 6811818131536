import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { plugin, configureFormKit } from './plugins/formKitPlugin';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import './assets/index.css';
import App from './App.vue';
import router from './router';
import { configureI18n } from './plugins/i18nPlugin';
import { envs } from './env';
import { initSentry } from './plugins/sentryPlugin';
import { useFirebase } from './services/firebase';

const isStaging = envs.VITE_IS_STAGING_ENV === 'true';
const { initRemoteConfig } = useFirebase();

const buildApp = async () => {
  const app = createApp(App);

  if (!isStaging) {
    initSentry(app, router);
  }

  app
    .use(router)
    .use(createPinia().use(piniaPluginPersistedstate))
    .use(plugin, configureFormKit)
    .use(await configureI18n())
    .mount('#app');
};

isStaging ? console.info('staging env') : void 0;

buildApp();
initRemoteConfig();
