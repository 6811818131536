import { useUserStore } from '../stores/userStore';
import http, { useAxiosUtils } from './axiosClient';
import { useHandleError } from './logErrors';
import { PaymentTerm } from './useOutsetaService';
import { UserDTO } from './userDataService';
import { toast } from '../components/subcomponents/toast/toast';
import { useTenantService } from './tenantService';

export interface outsetaID {
  ID: string;
}

export type OutsetaUserInfo = Pick<UserDTO, 'outsetaAccountStage' | 'outsetaAccountStageLabel' | 'outsetaPlanName' | 'outsetaPlanRate'> & {
  isValid: boolean;
};

export type PlanRates = {
  [paymentTerm in PaymentTerm]: { planUid: string; price: number; trialDays?: number };
};

export type Plan = {
  planName: string;
  freeTrialDays: number;
  planFamilyUid: string;
  planFamilyName: string;
  rates: PlanRates;
  planAddOnUid: string | null;
};

export default function useSubscriptionService() {
  const { getHeaders } = useAxiosUtils();
  const { throwError, handleError } = useHandleError();
  const { getTenantName } = useTenantService();
  const userStore = useUserStore();

  function updateOutsetaUserInfo(data: outsetaID) {
    return http.post<OutsetaUserInfo>('/api/subscription/updateOutsetaUserInfo', data, getHeaders());
  }
  async function handleLoggedInUserSubscription(outsetaId: string): Promise<void> {
    const response = await updateOutsetaUserInfo({
      ID: outsetaId,
    });
    userStore.updateOutsetaUserInfo(response.data);
    if (!userStore.loggedInInfo?.user?.outsetaId) {
      userStore.updateUserStoreWithInfo({ outsetaId });
    }
  }

  async function createOutsetaAccount(planFamilyName: string) {
    if (!userStore.loggedInInfo?.user) return;
    let res;
    try {
      res = (await http.post<UserDTO>('/api/subscription/create', { planFamilyName }, getHeaders())).data;
    } catch (error) {
      const errorDetails = handleError(error);
      toast(errorDetails || 'Something went wrong during account creation', { type: 'err' });
      return;
    }

    await userStore.updateUserStoreWithInfo(res);
    return res.outsetaId;
  }

  async function checkExistingOutsetaAccountByEmail(email: string) {
    try {
      const res = await http.post<{ isEmailValid: boolean }>('/api/subscription/checkExistingOutsetaAccountByEmail', { email });
      return res.data.isEmailValid;
    } catch (error) {
      throwError(error);
      return false;
    }
  }

  async function getPlansByTenant() {
    const tenant = userStore.loggedInInfo?.user?.tenant.tenant || getTenantName();
    if (!tenant) {
      throwError('Cannot retrieve plans without a tenant');
      return;
    }
    const contentFlags = await import(`./../views/${tenant}/contentFlags.ts`);
    return await getPlans(contentFlags.planFamilyName);
  }

  async function getPlans(planFamilyName: string) {
    try {
      const res = await http.get<Plan>('/api/subscription/plans', { params: { planFamilyName } });
      return res.data;
    } catch (error) {
      throwError(error);
    }
  }

  return {
    updateOutsetaUserInfo,
    handleLoggedInUserSubscription,
    createOutsetaAccount,
    getPlansByTenant,
    getPlans,
    checkExistingOutsetaAccountByEmail,
  };
}
