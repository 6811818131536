import { LeadInterest, LeadMembership, SourceOptions } from '@/services/leadDataService';
import { MenuItems } from '@/components/MenuComponent.vue';

export const sourcesToShow: SourceOptions[] = [
  'CSV',
  'Manually Created',
  'CONTACT_ME_FORM',
  'INFORMATION_FLOW_FORM',
  'INFORMATION_FLOW',
  'LEAD_MAGNET',
  'US bot',
  'DE bot',
  'ES bot',
  'EN bot',
];
export const membershipsToShow: LeadMembership[] = ['Lead', 'User', 'Customer', 'Smartship', 'Promoter', 'Lost'];

export const interestsToShow: LeadInterest[] = ['business', 'product'];
export const menuItemsToHide: MenuItems[] = [];
