import { toRefs, reactive, computed, ref } from 'vue';
import { defineStore } from 'pinia';
import router from '../router';
import useAuthService, { LoginCredentials, RegistrationCredentials } from '../services/authService';
import { useUserService, UserDTO } from '../services/userDataService';
import { AccountLanguage } from '../services/useLanguageService';
import useSubscriptionService, { OutsetaUserInfo } from '../services/subscriptionService';
import useI18nUtils from '../services/useI18nUtils';
import { useStatsService } from '../services/statsService';
import { useHandleError } from '../services/logErrors';
import { useHotjar } from '../services/useHotjar';
import { useAnalyticService, Analytic } from '../services/analyticService';
import { LinkMate, useLinkMateService } from '../services/linkMateService';
import { useFirebase } from '../services/firebase';
import { useRouterHelpers } from '@/router/useRouterHelpers';
import * as Sentry from '@sentry/vue';

export interface Store {
  loggedInInfo?: {
    jwt?: string;
    user?: UserDTO;
  };
  isUserSubscribed: boolean;
  isDataInEdit: boolean;
  isSagaLinkEnabled: boolean;
  adminStatus: {
    isAdminModeActive: boolean;
    adminModeLanguage?: AccountLanguage;
    intervalId?: number;
  };
}

export type LoginResponse = {
  loginSuccessful: boolean;
  isOutsetaRegistrationNeeded: boolean;
};

const HEARTBEAT_INTERVAL = 1000 * 60 * 1;
const { throwError } = useHandleError();
const i18nUtils = useI18nUtils();
const hotjar = useHotjar();
const { syncRemoteConfig, setUserIdOnAnalytic, getRemoteConfigValue } = useFirebase();
const { changeRoute } = useRouterHelpers(router);

export const useUserStore = defineStore(
  'UserStore',
  () => {
    //hold state of current logged in user
    const state = reactive<Store>({
      loggedInInfo: undefined,
      isUserSubscribed: false,
      isDataInEdit: false,
      isSagaLinkEnabled: false,
      adminStatus: {
        isAdminModeActive: false,
        adminModeLanguage: undefined,
        intervalId: undefined,
      },
    });

    const login = async (credentials: LoginCredentials): Promise<LoginResponse> => {
      const resp = await useAuthService().login(credentials);
      state.loggedInInfo = { jwt: resp.data.jwt };
      if (resp.data.user) {
        await internalUpdateUserStore(resp.data.user.id);

        let outsetaId = state.loggedInInfo.user?.outsetaId;
        if (!outsetaId) return { loginSuccessful: false, isOutsetaRegistrationNeeded: true };
        await useSubscriptionService().handleLoggedInUserSubscription(outsetaId);
        useUserService().resetLastUpdateTimestamp(new Date());
        return { loginSuccessful: true, isOutsetaRegistrationNeeded: false };
      }
      return { loginSuccessful: false, isOutsetaRegistrationNeeded: false };
    };
    // update Store
    const updateLoggedInUserInfo = async () => {
      if (state.loggedInInfo?.user?.id) {
        try {
          await internalUpdateUserStore(state.loggedInInfo.user.id);

          if (state.loggedInInfo.user?.outsetaId) {
            await useSubscriptionService().handleLoggedInUserSubscription(state.loggedInInfo.user?.outsetaId);
          }
        } catch (error) {
          //api request to include ?populate=* to return the imagelink for landbot and profile pictures
          throwError(error);
        }
      }
    };

    const updateLinkMate = (linkMateId: number, updatedLinkMate: LinkMate) => {
      if (!state.loggedInInfo?.user) {
        throwError('No user found in the store');
        return;
      }
      state.loggedInInfo.user.profileTree = {
        id: linkMateId,
        ...state.loggedInInfo.user.profileTree,
        ...updatedLinkMate,
      };
    };

    const updateLinkMateViaAPI = async (linkMateId: number) => {
      const { getLinkMate } = useLinkMateService();
      const updatedLinkMate = (await getLinkMate(linkMateId)).attributes;
      await updateLinkMate(linkMateId, { ...updatedLinkMate, image: updatedLinkMate.image.data?.attributes });
    };

    const register = async (credentials: RegistrationCredentials) => {
      const resp = await useAuthService().register(credentials);
      if (resp.data.user) {
        state.loggedInInfo = {
          user: {
            username: resp.data.user.username,
            firstName: resp.data.user.firstName,
            lastName: resp.data.user.lastName,
            id: resp.data.user.id,
            tenant: resp.data.user.tenant,
            email: resp.data.user.email,
            outsetaId: resp.data.user.outsetaId,
            confirmed: resp.data.user.confirmed,
            role: resp.data.user.role,
            createdAt: resp.data.user.createdAt,
          },
        };
      }
    };

    const logout = async () => {
      state.loggedInInfo = {};
      state.adminStatus = {
        isAdminModeActive: false,
        adminModeLanguage: undefined,
      };
      state.isUserSubscribed = false;
      state.isDataInEdit = false;
      state.isSagaLinkEnabled = false;
      Sentry.getCurrentScope().clear();
      Sentry.setUser(null);
      changeRoute('HostLogin');
    };

    const isLinkGenerated = computed(() => {
      return !!(
        state.loggedInInfo?.user?.id &&
        state.loggedInInfo?.user?.shopLink &&
        state.loggedInInfo?.user?.imageLink?.url &&
        state.loggedInInfo?.user?.phoneNumber?.length &&
        state.loggedInInfo?.user?.countryCode?.length &&
        state.loggedInInfo?.user?.facebookLink
      );
    });

    const missingInfosToGenerateLinkMate = computed(() => {
      if (!state.loggedInInfo?.user) return [];
      const missingInfos: string[] = [];
      if (!state.loggedInInfo?.user?.shopLink) missingInfos.push('profile.shop_link');
      if (!state.loggedInInfo?.user?.imageLink?.url) missingInfos.push('profile.picture');
      if (!state.loggedInInfo?.user?.phoneNumber?.length) missingInfos.push('profile.phone_number');
      if (!state.loggedInInfo?.user?.countryCode?.length) missingInfos.push('profile.country_code');
      if (!state.loggedInInfo?.user?.facebookLink) missingInfos.push('profile.facebook_link');
      return missingInfos;
    });

    const updateOutsetaUserInfo = async (value: OutsetaUserInfo) => {
      state.isUserSubscribed = value.isValid;
      if (state.loggedInInfo?.user) {
        state.loggedInInfo.user.outsetaAccountStage = value.outsetaAccountStage;
        state.loggedInInfo.user.outsetaAccountStageLabel = value.outsetaAccountStageLabel;
        state.loggedInInfo.user.outsetaPlanName = value.outsetaPlanName;
        state.loggedInInfo.user.outsetaPlanRate = value.outsetaPlanRate;
      }
    };

    const updateUserStoreWithInfo = async (payload: Partial<UserDTO>) => {
      if (state.loggedInInfo?.user) {
        state.loggedInInfo.user = {
          ...state.loggedInInfo.user,
          ...payload,
        };
      }
    };

    const setIsDataInEdit = async (value: boolean) => {
      state.isDataInEdit = value;
    };

    const setIsAdminMode = async (value: boolean) => {
      if (state.loggedInInfo?.user?.role.name !== 'Admin') return;
      state.adminStatus.isAdminModeActive = value;
    };

    const setAdminModeLanguage = async (value?: AccountLanguage) => {
      if (state.loggedInInfo?.user?.role.name !== 'Admin') return;
      state.adminStatus.adminModeLanguage = value;
    };

    const updateChatbotClickCount = async () => {
      if (!state.loggedInInfo?.user?.id) return;
      const clickStats = await useStatsService().getChatbotClickCount(state.loggedInInfo.user.id);
      state.loggedInInfo.user.currentMonthClicks = clickStats.currentMonthClicks;
      state.loggedInInfo.user.lastClickTimestamp = clickStats.lastClickTimestamp;
      state.loggedInInfo.user.lastMonthClicks = clickStats.lastMonthClicks;
    };

    const updateLastAccessDate = (userId: number) => {
      const analyticPayload: Partial<Analytic> = {
        eventName: 'lastAccessedDate',
        eventDataType: 'date',
        eventValue: new Date().toISOString(),
        lastDateEvent: new Date(),
      };
      if (state.loggedInInfo?.jwt) {
        try {
          useAnalyticService().upsertAnalytic('lastAccessedDate', userId, analyticPayload, false);
        } catch (error) {
          throwError(error);
        }
      }
    };

    const internalUpdateUserStore = async (userId: number) => {
      try {
        const user = await useUserService().getUser();
        setUserIdOnAnalytic(user.data.id);
        await syncRemoteConfig();
        state.isSagaLinkEnabled = new Date(user.data.createdAt) < new Date(getRemoteConfigValue('saga_link_expire_date'));

        state.loggedInInfo = {
          //load previous jwt into new object
          ...state.loggedInInfo,
          user: {
            ...user.data,
          },
        };
        //handle undefined in Database
        if (state.loggedInInfo.user) {
          state.loggedInInfo.user.accountLanguage = await i18nUtils.setLanguage(state.loggedInInfo.user?.accountLanguage);
          hotjar.identify(state.loggedInInfo.user);
        }
        updateChatbotClickCount();
        Sentry.setUser({
          id: state.loggedInInfo.user?.id,
          email: state.loggedInInfo.user?.email,
        });
      } catch (error) {
        throwError(error);
      }
      updateLastAccessDate(userId);
    };

    const adminHeartbeat = async () => {
      if (!state.adminStatus.adminModeLanguage) return;
      try {
        await useAuthService().handleHeartbeat(state.adminStatus.adminModeLanguage);
      } catch (error) {
        await adminLogout();
      }
    };

    const startAdminHeartBeat = async () => {
      if (!state.adminStatus.isAdminModeActive || state.adminStatus.intervalId) return;
      await adminHeartbeat();
      state.adminStatus.intervalId = window.setInterval(() => {
        adminHeartbeat();
      }, HEARTBEAT_INTERVAL);
    };

    const stopAdminHeartBeat = () => {
      if (state.adminStatus.intervalId) {
        window.clearInterval(state.adminStatus.intervalId);
        state.adminStatus.intervalId = undefined;
      }
    };

    const adminLogin = async (language: AccountLanguage) => {
      await useAuthService().adminLogin(language);
      setAdminModeLanguage(language);
      setIsAdminMode(true);
      await startAdminHeartBeat();
    };

    const adminLogout = async () => {
      try {
        if (!state.adminStatus.adminModeLanguage) throw new Error('No language set for admin mode');
        await useAuthService().deleteAdminSession(state.adminStatus.adminModeLanguage);
      } catch (error) {
        throwError(error);
      }
      setAdminModeLanguage(undefined);
      setIsAdminMode(false);
      stopAdminHeartBeat();
    };

    //return reactive state toRefs and function (actions in OptionsAPI)
    return {
      ...toRefs(state),
      login,
      register,
      updateLoggedInUserInfo,
      logout,
      isLinkGenerated,
      missingInfosToGenerateLinkMate,
      updateOutsetaUserInfo,
      setAdminModeLanguage,
      setIsAdminMode,
      setIsDataInEdit,
      updateChatbotClickCount,
      updateLinkMate,
      updateLinkMateViaAPI,
      updateUserStoreWithInfo,
      adminLogout,
      adminLogin,
      startAdminHeartBeat,
      stopAdminHeartBeat,
    };
  },
  {
    persist: true,
  }
);
