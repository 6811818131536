export default `<svg width="36" height="26" viewBox="0 0 43 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.0593 0.574371H21.3861V12.1584V17.505V29.089H24.0593V17.505H42.7721V12.1584H24.0593V0.574371Z" fill="#D80027"/>
<path d="M32.8963 19.7909L42.7719 25.2774V19.7909H32.8963ZM26.0349 19.7909L42.7719 29.0892V26.4598L30.7679 19.7909H26.0349ZM38.3137 29.0892L26.0349 22.267V29.0892H38.3137Z" fill="#0052B4"/>
<path d="M26.0349 19.7909L42.7719 29.0892V26.4598L30.7679 19.7909H26.0349Z" fill="white"/>
<path d="M26.0349 19.7909L42.7719 29.0892V26.4598L30.7679 19.7909H26.0349Z" fill="#D80027"/>
<path d="M35.2248 9.87287L42.7719 5.68003V9.87287H35.2248ZM26.0349 8.69045V0.574514H40.6424L26.0349 8.69045Z" fill="#0052B4"/>
<path d="M30.7681 9.87274L42.7722 3.20379L42.7721 0.574637L26.0352 9.87274H30.7681Z" fill="#D80027"/>
<path d="M-4.00543e-05 0.574356L21.386 0.574356V29.0891H-4.00543e-05V0.574356Z" fill="white"/>
<path d="M-4.00543e-05 0.574356L21.386 0.574356V2.76716H-4.00543e-05V0.574356ZM-4.00543e-05 4.95996H21.386V7.15277H-4.00543e-05V4.95996ZM-4.00543e-05 9.34557H21.386V11.5384H-4.00543e-05V9.34557ZM-4.00543e-05 13.7312H21.386V15.924H-4.00543e-05V13.7312ZM-4.00543e-05 18.1251H21.386V20.3179H-4.00543e-05V18.1251ZM-4.00543e-05 22.5107H21.386V24.7035H-4.00543e-05V22.5107ZM-4.00543e-05 26.8963H21.386V29.0891H-4.00543e-05V26.8963Z" fill="#D80027"/>
<path d="M-4.00543e-05 0.574356L21.386 0.574356V15.924H-4.00543e-05V0.574356Z" fill="#2E52B2"/>
<path d="M3.98495 12.1556L3.65144 11.0884L3.28458 12.1556H2.18401L3.07614 12.7976L2.74264 13.8648L3.65144 13.2061L4.53523 13.8648L4.19339 12.7976L5.10219 12.1556H3.98495ZM8.67905 12.1556L8.3372 11.0884L7.98702 12.1556H6.88645L7.77858 12.7976L7.44507 13.8648L8.3372 13.2061L9.23767 13.8648L8.90417 12.7976L9.79629 12.1556H8.67905ZM13.3898 12.1556L13.0313 11.0884L12.6978 12.1556H11.5722L12.4894 12.7976L12.1392 13.8648L13.0313 13.2061L13.9484 13.8648L13.5983 12.7976L14.4904 12.1556H13.3898ZM18.0756 12.1556L17.7421 11.0884L17.3919 12.1556H16.283L17.1835 12.7976L16.85 13.8648L17.7421 13.2061L18.6425 13.8648L18.284 12.7976L19.2012 12.1556H18.0756ZM8.3372 6.85282L7.98702 7.92004H6.88645L7.77858 8.57872L7.44507 9.62926L8.3372 8.97893L9.23767 9.62926L8.90417 8.57872L9.79629 7.92004H8.67905L8.3372 6.85282ZM3.65144 6.85282L3.28458 7.92004H2.18401L3.07614 8.57872L2.74264 9.62926L3.65144 8.97893L4.53523 9.62926L4.19339 8.57872L5.10219 7.92004H3.98495L3.65144 6.85282ZM13.0313 6.85282L12.6978 7.92004H11.5722L12.4894 8.57872L12.1392 9.62926L13.0313 8.97893L13.9484 9.62926L13.5983 8.57872L14.4904 7.92004H13.3898L13.0313 6.85282ZM17.7421 6.85282L17.3919 7.92004H16.283L17.1835 8.57872L16.85 9.62926L17.7421 8.97893L18.6425 9.62926L18.284 8.57872L19.2012 7.92004H18.0756L17.7421 6.85282ZM3.65144 2.63397L3.28458 3.68451H2.18401L3.07614 4.34319L2.74264 5.40207L3.65144 4.7434L4.53523 5.40207L4.19339 4.34319L5.10219 3.68451H3.98495L3.65144 2.63397ZM8.3372 2.63397L7.98702 3.68451H6.88645L7.77858 4.34319L7.44507 5.40207L8.3372 4.7434L9.23767 5.40207L8.90417 4.34319L9.79629 3.68451H8.67905L8.3372 2.63397ZM13.0313 2.63397L12.6978 3.68451H11.5722L12.4894 4.34319L12.1392 5.40207L13.0313 4.7434L13.9484 5.40207L13.5983 4.34319L14.4904 3.68451H13.3898L13.0313 2.63397ZM17.7421 2.63397L17.3919 3.68451H16.283L17.1835 4.34319L16.85 5.40207L17.7421 4.7434L18.6425 5.40207L18.284 4.34319L19.2012 3.68451H18.0756L17.7421 2.63397Z" fill="white"/>
</svg>
`;
