import { envs } from '../env';
import { UserDTO } from './userDataService';

export type HotjarEventFunction = (event: 'event', eventType: string) => void;
export type HotjarIdentifyFunction = (
  event: 'identify',
  id: number,
  attributes: Record<string, number | string | Date | boolean | undefined>
) => void;

const VITE_IS_STAGING_ENV = envs.VITE_IS_STAGING_ENV;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createHotJarScript = (h: any, o: Document, t: string, j: string) => {
  h.hj =
    h.hj ||
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function (...args: any[]) {
      (h.hj.q = h.hj.q || []).push(args);
    };
  h._hjSettings = { hjid: 3076747, hjsv: 6 };
  const a = o.getElementsByTagName('head')[0];
  const r = o.createElement('script');
  r.async = true;
  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
  r.onerror = e => {
    console.warn('hotjar not loaded due to ', e.toString());
  };
  a.appendChild(r);
  return r;
};

type HotjarEvent = 'openHelpCenter';

export function useHotjar() {
  const isMaster = VITE_IS_STAGING_ENV === 'false';

  const init = () => {
    if (isMaster) {
      createHotJarScript(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    }
  };

  const emitErrorEvent = () => {
    if (isMaster && window.hj) {
      window.hj('event', 'error_occurred');
    }
  };

  const emitHotjarEvent = (eventName: HotjarEvent) => {
    if (isMaster && window.hj) {
      window.hj('event', eventName);
    }
  };

  const identify = (user: UserDTO) => {
    if (isMaster && window.hj) {
      window.hj('identify', user.id, {
        outsetaAccountStageLabel: user.outsetaAccountStageLabel,
        outsetaAccountStage: user.outsetaAccountStage,
        createdAt: user.createdAt,
      });
    }
  };

  return {
    init,
    emitErrorEvent,
    identify,
    emitHotjarEvent,
  };
}
