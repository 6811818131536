export default `<svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z" fill="url(#paint0_linear_7835_11308)"/>
<defs>
<linearGradient id="paint0_linear_7835_11308" x1="12" y1="0" x2="12" y2="24" gradientUnits="userSpaceOnUse">
<stop stop-color="#9A7905"/>
<stop offset="1" stop-color="#E4BD44"/>
</linearGradient>
</defs>
</svg>
`;
