import { getCurrentLocale } from '../plugins/i18nPlugin';
import { useUserStore } from '../stores/userStore';
import { DMOColors } from './dmoService';
import { LeadMagnetColors } from './linkMateLinkService';
import { AccountLanguage } from './useLanguageService';
import { formatDate } from '@vueuse/core';
export interface FormatOption {
  locale?: AccountLanguage;
  formatString?: string;
}

export function useUtilsService() {
  const DAY_IN_SECONDS = 1000 * 3600 * 24;
  const trimPhoneNumer = (phone?: string) => {
    if (!phone) {
      return phone;
    }
    return phone.replaceAll(' ', '');
  };

  const trimAndSanitizeLink = (link?: string, addTrailingSlash = false) => {
    if (!link) {
      return link;
    }
    let tmp = link.trim();
    if (tmp.includes('://')) {
      tmp = tmp.replace(tmp.substring(0, tmp.indexOf('://')), 'https');
    } else {
      tmp = 'https://' + tmp;
    }
    if (addTrailingSlash && !tmp.endsWith('/')) {
      tmp += '/';
    }
    return tmp;
  };

  const getStartOfTheDay = (date: Date) => {
    const tmp = new Date(date);
    tmp.setHours(0);
    return tmp;
  };

  const getEndOfTheDay = (date: Date) => {
    const tmp = new Date(date);
    tmp.setHours(23);
    tmp.setMinutes(59);
    tmp.setSeconds(59);
    tmp.setMilliseconds(999);
    return tmp;
  };

  const getNowDateMidday = () => {
    const tmp = new Date();
    tmp.setHours(12, 0, 0, 0);
    return tmp;
  };

  const getDateMidday = (year: number, month: number, day: number) => {
    const tmp = new Date(year, month, day);
    tmp.setHours(12, 0, 0, 0);
    return tmp;
  };

  const getStartOfCalendarMonth = (date: Date) => {
    const tmp = new Date(date);
    tmp.setDate(1);
    tmp.setHours(0, 0, 0, 0);
    const firstDay = tmp.getDay();
    const i = firstDay - 1;
    tmp.setDate(-i);
    return tmp;
  };

  const getEndOfCalendarMonth = (date: Date) => {
    const startOfCalendarMonth = getStartOfCalendarMonth(date);
    const tmp = new Date(startOfCalendarMonth);
    tmp.setDate(startOfCalendarMonth.getDate() + 42);
    return tmp;
  };

  const generateNumericalId = () => {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 10000);
    const temporaryId = parseInt(`${timestamp}${random}`, 10);

    return temporaryId;
  };

  const compareDates = (d1: Date, d2: Date, compareFunc: (n1: number, n2: number) => boolean) => {
    const year1 = d1.getFullYear();
    const year2 = d2.getFullYear();
    if (year1 != year2) {
      return compareFunc(year1, year2);
    }
    const month1 = d1.getMonth();
    const month2 = d2.getMonth();
    if (month1 != month2) {
      return compareFunc(month1, month2);
    }
    const date1 = d1.getDate();
    const date2 = d2.getDate();
    return compareFunc(date1, date2);
  };

  const calculateStartOfDayFromToday = (days: number) => {
    const dateToReturn = new Date();
    dateToReturn.setDate(dateToReturn.getDate() + days);
    dateToReturn.setHours(0, 0, 0, 0);
    return dateToReturn;
  };

  const calculateDatesDifference = (d1: Date, d2: Date) => {
    const differenceInTime = Math.abs(d1.getTime() - d2.getTime());
    const differenceInDays = differenceInTime / DAY_IN_SECONDS;
    return Math.floor(differenceInDays);
  };

  const alphabeticalOrder = (el1: string, el2: string) => {
    const nameA = el1.toUpperCase();
    const nameB = el2.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  const getFormattedDate = (dt?: Date | string, withTime: boolean = false, formatOptions?: FormatOption) => {
    if (!dt) return;
    if (typeof dt === 'string') dt = new Date(dt);
    if (withTime) {
      return formatDate(dt, formatOptions?.formatString || 'YYYY.MM.DD - HH:mm');
    }
    return formatDate(dt, formatOptions?.formatString || 'YYYY.MM.DD');
  };

  const getDateInfoAsObject = (dateString?: string) => {
    if (!dateString) return;
    const userStore = useUserStore();
    const date = new Date(dateString);
    const locale = userStore.loggedInInfo?.user?.accountLanguage || getCurrentLocale();
    const dateOptions: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    };
    const timeOptions: Intl.DateTimeFormatOptions = { hourCycle: 'h12', hour: 'numeric', minute: '2-digit' };
    return {
      date: new Intl.DateTimeFormat(locale, dateOptions).format(date),
      time: new Intl.DateTimeFormat('en-GB', timeOptions).format(date),
      zoneOffset: getTimezoneOffset(date),
    };
  };

  const getTimezoneOffset = (date: Date) => {
    return date.getTimezoneOffset() * -1;
  };

  const getOffsetFromTimeZone = (timeZone = 'UTC') => {
    const now = new Date();
    const utcDate = new Date(now.toLocaleString('en-US', { timeZone: 'UTC' }));
    const tzDate = new Date(now.toLocaleString('en-US', { timeZone }));
    return (tzDate.getTime() - utcDate.getTime()) / 6e4;
  };

  const euOrUs = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone.startsWith('Europe') ? 'EU' : 'US';
  };

  const computeColor = (color?: LeadMagnetColors | DMOColors | string) => {
    switch (color) {
      case 'teal':
        return { text: 'text-teal-500', bgLight: 'bg-teal-500/50', bg: 'bg-teal-500' };
      case 'green':
        return { text: 'text-green-600', bgLight: 'bg-green-600/50', bg: 'bg-green-600' };
      case 'blue':
        return { text: 'text-blue-500', bgLight: 'bg-blue-500/50', bg: 'bg-blue-500' };
      case 'purple':
        return { text: 'text-purple-500', bgLight: 'bg-purple-500/50', bg: 'bg-purple-500' };
      case 'gray':
        return { text: 'text-gray-500', bgLight: 'bg-gray-500/50', bg: 'bg-gray-500' };
      case 'orange':
        return { text: 'text-orange-500', bgLight: 'bg-orange-500/50', bg: 'bg-orange-500' };
      case 'yellow':
        return { text: 'text-yellow-500', bgLight: 'bg-yellow-500/50', bg: 'bg-yellow-500' };
      default:
        return { text: 'text-blue-500', bgLight: 'bg-blue-500/50', bg: 'bg-blue-500' };
    }
  };

  function capitalizeFirstLetter(str: string): string;
  function capitalizeFirstLetter(str: undefined): undefined;
  function capitalizeFirstLetter(str: null): undefined;
  function capitalizeFirstLetter(str: undefined | string): undefined | string;
  function capitalizeFirstLetter(str: undefined | string | null): undefined | string;
  function capitalizeFirstLetter(str: string | null | undefined) {
    if (!str) return;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const getInitialsInCapitalLetter = (name?: string) => {
    if (!name) return 'NA';
    const names = name.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const validateEmail = (email: string) => {
    try {
      const emailRegEx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegEx.test(email.toLowerCase());
    } catch (err) {
      return false;
    }
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    try {
      const phoneRegEx = /(?:([+]\d{1,4})[-.\s]?)?(?:[(](\d{1,3})[)][-.\s]?)?(\d{1,4})[-.\s]?(\d{1,4})[-.\s]?(\d{1,9})/;
      return phoneRegEx.test(phoneNumber);
    } catch (err) {
      return false;
    }
  };

  return {
    trimPhoneNumer,
    trimAndSanitizeLink,
    getNowDateMidday,
    getDateMidday,
    compareDates,
    alphabeticalOrder,
    getEndOfTheDay,
    getStartOfTheDay,
    getFormattedDate,
    euOrUs,
    computeColor,
    calculateDatesDifference,
    getDateInfoAsObject,
    getTimezoneOffset,
    getStartOfCalendarMonth,
    getEndOfCalendarMonth,
    generateNumericalId,
    getInitialsInCapitalLetter,
    calculateStartOfDayFromToday,
    capitalizeFirstLetter,
    getOffsetFromTimeZone,
    validateEmail,
    validatePhoneNumber,
  };
}
